import React, { Component } from "react";
import {
    Link
  } from "react-router-dom";
import './Home.css';

class Home extends React.Component {
  render() {
    return (
      <div className="App">
        {/* NAVBAR */}
        <nav id="navbar" className="navbar fixed-top navbar-expand-lg navbar-header navbar-mobile">
          <div className="navbar-container container">
            {/* LOGO */}
            <div className="navbar-brand">
              <a className="navbar-brand-logo" href="#top">
                <img src="assets/images/phanmemtinhbang.com.png" />
              </a>
            </div>

            <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
            </div>
          </div>
        </nav>
        {/* SECTION LABEL */}
        <div id="top"></div>
        {/* WRAPPER */}
        <div className="wrapper">
          <div className="header">
            <div className="container header-container padding0px">
              <div className="col-lg-6 header-img-section padding0px">
                <img src="assets/images/bamien.gif" />
              </div>
              <div className="col-lg-6 header-title-section">
              <h1 className="header-title">XOSOPRO Phần mềm tính bảng dành cho IOS IPhone</h1>
                <p className="header-title-text">Dành cho cách chơi theo kiểu miền Nam</p>
                <div className="learn-more-btn-section">
                Kế Toán 3M là ứng dụng tính tiền loto cho đại lý xổ số khu vực miền Nam. 
                Phần mềm tự động tính toán thắng thua của một khách dựa vào kết quả xổ số 
                của cả ba miền Bắc Trung Nam một cách nhanh chóng và hạn chế sai sót như cách tính bằng tay thông thường
                </div>
                {/* <div className="learn-more-btn-section">
                  <Link to="/mienbac" className="nav-link learn-more-btn">Video Hướng Dẫn</Link>
                  <a className="nav-link learn-extra-btn"
                    target="_self"
                    download
                    href="assets/files/XOSOPRO_6886_1714.apk">
                    <i className="fa fa-android" aria-hidden="true"></i> Tải phần mềm</a>
                </div>
                <div className="learn-more-btn-section">
                  <a className="nav-link learn-extra-btn"
                    target="_self"
                    download
                    href="assets/files/ZALO-21-01-01-1.apk">
                    <i className="fa fa-android" aria-hidden="true"></i> Tải Zalo bản cũ</a>
                </div> */}
              </div>
            </div>
          </div>
          {/* <div className="header" style={{ "padding": "10px 0 100px 0" }}>
            <div className="container header-container padding0px">
              <div className="col-lg-6 header-img-section padding0px">
                <img src="assets/images/bamien.gif" />
              </div>
              <div className="col-lg-6 header-title-section">
                <h1 className="header-title">KẾ TOÁN 3M Phần mềm tính bảng 3 Miền</h1>
                <p className="header-title-text">Dành cho cách chơi theo kiểu miền Nam</p>
                <div className="learn-more-btn-section">
                <Link to="/ba-mien" className="nav-link learn-more-btn">Video Hướng Dẫn</Link>
                  <a className="nav-link learn-extra-btn"
                    target="_self"
                    download
                    href="assets/files/DL001_123.apk">
                    <i className="fa fa-android" aria-hidden="true"></i> Tải phần mềm</a>
                </div>
                <div className="learn-more-btn-section">
                  <a className="nav-link learn-extra-btn"
                    target="_self"
                    download
                    href="assets/files/ZALO-21-01-01-1.apk">
                    <i className="fa fa-android" aria-hidden="true"></i> Tải Zalo bản cũ</a>
                </div>
              </div>
            </div>
          </div> */}

          {/* TUTORS */}
          <div className="tutors-section">
            <div className="tutors-section-bg-graphics-section">
              <img src="assets/images/tutors-section-bg-img.png" />
            </div>
            <div className="container tutors-container">
              <div className="pricing-title">
                <h2>Liên hệ</h2>
                <p> Phần mềm được cung cấp dùng thử miễn phí dài hạn. Vui lòng liên hệ để được hướng dẫn sử dụng.</p>
              </div>
              <div className="container tutors-img-container">
                <div className="col-lg-2 col-md-6 col-sm-6 col-6 tutors-card-section">
                  <div className="tutors-card">
                    <div className="tutors-card-icon-section">
                      {/* <img src="assets/images/tutor1.svg" /> */}
                      <i className="fa fa-phone" aria-hidden="true" style={{ "fontSize": "70px" }}></i>
                    </div>
                    <h2><a href="tel:0981715186">0981.715.186</a></h2>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 col-6 tutors-card-section">
                  <div className="tutors-card">
                    <div className="tutors-card-icon-section">
                      <i className="fa fa-facebook" aria-hidden="true" style={{ "fontSize": "70px" }}></i>

                    </div>
                    <h2><a href="https://www.facebook.com/100011505182631">Hà Đức Tuấn</a></h2>
                  </div>
                </div>
              </div>
              <div className="header" style={{"paddingTop":"20px"}}></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
