import React, { Component } from "react";
import logo from './logo.svg';
import './App.css';
// import { ReactComponent } from '*.svg';
import MienBac from '../src/components/MienBac/MienBac'
import BaMien from '../src/components/BaMien/BaMien'
import Home from '../src/components/Home/Home'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

class App extends React.Component {
  render() {
    return (


      <div className="App">
        <Router>
          <div>
            {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/mienbac">
                <MienBac />
              </Route>
              <Route path="/ba-mien">
                <BaMien />
              </Route>
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
